import Vue from 'vue'
import VueRouter from 'vue-router'
import MainForm from '../components/MainForm.vue';

Vue.use(VueRouter)

const routes = [
  {  
    path: '/',
    redirect: '/data/'
  },
  {
    path: '/data/',
    name: 'data',
    component: MainForm
  }
]

const router = new VueRouter({
  //mode: 'history',
  //base: process.env.BASE_URL,
  routes
})

export default router
