import Vue from 'vue'
import App from './App.vue'
import store from './store.js'
import router from './router'
import './plugins/bootstrap-vue'

import { BootstrapVue } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.config.productionTip = false

let vm = new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

export default {
  showError(message) {
    vm.$bvToast.toast(message, {
      title: 'Error',
      variant: 'danger',
      solid: true
    });
  },
  showInfo(message) {
    vm.$bvToast.toast(message, {
      title: 'Info',
      variant: 'info',
      solid: true
    });
  }
}
