import Vue from 'vue'
import Vuex from 'vuex'
import client from 'api-client'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: true,
    user: 'anonymous'
  },
  getters: {
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setUsername(state, username) {
      state.user = username;
    }
  },
  actions: {
    loadUserInfo({ commit }) {
      client.getUserInfo()
        .then(res => commit('setUsername', res.username));
    }
  },
  modules: {
  }
})
