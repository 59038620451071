const BASE_API_URL = process.env.VUE_APP_API_BASE_URL;

import axios from 'axios';
import store from '../../store';
import main from '../../main';

function apiRequest(options, showLoading = true, handleValidationErrors = false, handleAllErrors = false) {
  if (showLoading) {
    store.commit('setLoading', true);
  }
  return new Promise((resolve, reject) => {
    axios(options)
      .then(response => {
        if (response.status === 204) {
          resolve({});
        } else {
          resolve(response.data);
        }
        if (showLoading) {
          store.commit('setLoading', false);
        }
      })
      .catch(error => {
        if (handleAllErrors) {
          reject(error);
        } else {
          if (showLoading) {
            store.commit('setLoading', false);
          }
          if (handleValidationErrors && error.response && error.response.status === 400) {
            reject(error.response.data);
          } else {
            main.showError(getErrorMessage(error));
          }
        }
      });
  });
}

function getErrorMessage(error) {
  if (error.response && error.response.data && error.response.data.message) {
    return error.response.data.message;
  } else if (error.message) {
    return error.message;
  } else {
    return 'Unknown error';
  }
}


export default {
  getUserInfo() {
    let url = BASE_API_URL + 'user';
    return apiRequest({
      method: 'GET',
      url: url,
      withCredentials: true,
      headers: {
        'Cache-Control': 'no-cache'
      }
    }, false, false);
  },
  keepalive() {
    let url = BASE_API_URL + 'keepalive';
    return apiRequest({
      method: 'GET',
      url: url,
      withCredentials: true,
      headers: {
        'Cache-Control': 'no-cache'
      }
    }, false, false);
  },
  createIssue(formData) {
    let url = BASE_API_URL + 'create-issue';
    return apiRequest({
      method: 'POST',
      url: url,
      withCredentials: true,
      headers: {
        'Cache-Control': 'no-cache'
      },
      data: formData
    });
  }
}

