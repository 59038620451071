<template>
  <div id="app">
    <TopMenu />
    <div class="container">
      <router-view></router-view>
    </div>
    <div id="footer-fix"></div>
    <footer class="text-center" id="site-footer">
    &nbsp;Powered by <img alt="IA2 logo" src="./assets/ia2-logo-footer.png">
    <strong class="text-primary"><a href="http://www.ia2.inaf.it/" target="_blank">IA2</a></strong>&nbsp;
    </footer> 
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TopMenu from './components/TopMenu.vue'
import client from 'api-client'

export default {
  name: 'App',
  computed: mapState({
    loading: state => state.loading
  }),
  components: {
    TopMenu
  },
  mounted() {
    this.$store.dispatch('loadUserInfo');
    setInterval(client.keepalive, 60000);
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

#footer-fix {
  display: block;
  width: 100%;
  height: 100px;
}

#site-footer {
  color: #666;
  border-top: 1px #e7e7e7 solid;
  background-color: #f8f8f8;
  padding: 5px 0;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
}
</style>
