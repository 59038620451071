<template>
<div>
  <b-navbar toggleable="lg" type="dark" id="top-menu">
    <b-navbar-brand href="#/" class="d-none d-md-block">VLKB Private Data Authorization Request</b-navbar-brand>
    <b-navbar-nav class="ml-auto">
      <b-nav-item href="login" class="mr-4" v-if="user === 'anonymous'">Login</b-nav-item>
      <b-nav-item-dropdown :text="user" right v-if="user !== 'anonymous'">
        <b-dropdown-item href="vlkb-logout">Logout</b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-navbar>
</div>
</template>

<script>
export default {
  computed: {
    user() { return this.$store.state.user }
  }
}
</script>


<style>
#top-menu {
  //background-color: #FFA8A4;
  background-color: #3050EA;
  margin-bottom: 20px;
}

sup {
  color: yellow;
  margin-left: 2px;
}
</style>

