<template>
<div class="container">
  <b-card bg-variant="light" v-if="user !== 'anonymous'">
    <b-form @submit="onSubmit" @reset="onReset" v-if="user !== 'anonymous'">
      <b-form-group>
        <p id="welcome"><b>Welcome to the ViaLactea Knowledge Base Private Data Authorization Request</b></p>
        <p class="description"><b>The web form below, once filled and submitted, will open a ticket for us to evaluate your authorization request and act on it (approval/denial and notification). The email address you must provide (and confirm) is only used within the scope of this single request. If you provide a wrong address we will have no way get back to you for the subsequent notification(s) on the status of your request.</b></p>
<p class="description"><b>NOTE: authorization will be granted to the identity subtended by your email above (the one you used to log in to this web form application).</b></p>
<p class="description"><b>For support and inquiries please contact us at <a href="mailto:vlkb@inaf.it?subject=VLKB AuthZ webform">vlkb@inaf.it</a>.</b></p>
      </b-form-group>  

      <b-form-group label="Full name & Affiliation:" label-for="full_name_affiliation" description="e.g. John Smith, Massachusetts Institute of Technology">
        <b-form-input
          id="full_name_affiliation"
          v-model="form.name"
          placeholder="Enter your full name and affiliation"
          required
        ></b-form-input>
      </b-form-group>
   
      <b-form-group label="Email address:" label-for="email_field" description="We'll never share your email with anyone else and use it only within the scope of this specific request.">
        <b-form-input
          id="email_field"
          v-model="form.email"
          type="email"
          placeholder="Enter email"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Email address confirmation:" label-for="email_confirm_field">
        <b-form-input
          id="email_confirm_field"
          v-model="email"
          type="email"
          placeholder="Re-enter email to confirm it"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Tick the following checkboxes to ask authorization to access specific data collections or the full set of private data collections deployed through the VLKB. This is needed to allow you to consume those data through the ViaLactea Visual Analytics client (when logged in) or other API(s):" v-slot="{ ariaDescribedby }">
        <b-form-checkbox
          v-model="form.vlkbAllPrivate"
          :aria-describedby="ariaDescribedby"
          plain="true"
          value="yes"
          unchecked-value="no"
          @change="toggleVlkbGmsGroups"
        >All VLKB private data</b-form-checkbox>
      </b-form-group>

      <b-form-group label="or, specify what collections you are interested in:" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          v-model="form.vlkbGmsGroupsSelected"
          :options="vlkbGmsGroups"
          :aria-describedby="ariaDescribedby"
          plain="true"
          value="yes"
          unchecked-value="no"
          @change="untoggleVlkbAllPrivate"
        ></b-form-checkbox-group>
      </b-form-group>
    
      <b-form-group label="Please, report any comments you feel useful in the following free text box:" label-for="textarea">    
        <b-form-textarea
          id="textarea"
          v-model="form.comment"
          placeholder="(optional) comments, if any"
          rows="3"
          max-rows="6"
        ></b-form-textarea> 
      </b-form-group>
       
      <b-button type="submit" variant="primary">Submit</b-button>
      <b-button type="reset" variant="danger">Reset</b-button>
    </b-form>
  </b-card>
</div>
</template>

<script>
import client from 'api-client';
import main from '../main.js';
  export default {
    data() {
      return {
        form: {
          name: '',
          email: '',
          comment: '',
          vlkbAllPrivate: '',
          vlkbGmsGroupsSelected: []
        },
        email: '',
        vlkbGmsGroups: ['Group 1', 'Group 2', 'Group A', 'Group test'],
        show: true
      }
    },
    methods: {
      loadEmail() {
        client.getUserInfo()
          .then(res => this.form.email = res.email)
          .catch(error => {
            if (error.response && error.response.data) {
              main.showError(error.response.data.message);
            } else {
              main.showError('Unkonwn error');
            }
          })
      },
      toggleVlkbGmsGroups() {
        if(this.form.vlkbAllPrivate === "yes") {
          this.form.vlkbGmsGroupsSelected = this.vlkbGmsGroups.slice()
        }
        else {
          this.form.vlkbGmsGroupsSelected = []
        }
      },
      untoggleVlkbAllPrivate() {
        if(this.form.vlkbGmsGroupsSelected.length !== this.vlkbGmsGroups.length) {
          this.form.vlkbAllPrivate = "no";
        }
      },
      emailConfirmation() {
        return this.form.email === this.email ? true : false;
      },
      onSubmit(event) {
        event.preventDefault()
        //alert(JSON.stringify(this.form))

        if (!this.emailConfirmation()) {
          alert("'Email' and 'Email confirmation' fields are not the same!")
        }
        else if (this.form.vlkbAllPrivate == '' && !this.form.vlkbGmsGroupsSelected.length) {
          alert("You must select at least one checkbox!")
        }
        else {
          client.createIssue(this.form)
          .then(function() {
            main.showInfo('Request successfully submitted');
          })
          .catch(error => {
            if (error.response && error.response.data) {
              main.showError(error.response.data.message);
            } else {
              main.showError('Error while accessing node');
            }
          })
        }
      },
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form.name = ''
        this.form.email = ''
        this.form.comment = ''
        this.form.vlkbAllPrivate = ''
        this.form.vlkbGmsGroupsSelected = []

        this.email = ''   

        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      }
    },
    computed: {
      user() { return this.$store.state.user }
    },
    beforeMount() {
      this.loadEmail()
    }
  }
</script>

<style>
  p.description {
      text-align: left
  };
</style>
